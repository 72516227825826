import logo from './logo.svg';
import './App.css';
import React, { useState } from 'react';
import axios from 'axios';

function SubscriptionForm() {
  const [email, setEmail] = useState('');
  const [statusMessage, setStatusMessage] = useState('');

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      setStatusMessage('Iltimos, to\'g\'ri email manzilini kiriting.');
      return;
    }

    const token = 'nuqta';

    try {
      const response = await axios.get(`https://nuqtauz.com/api/?email=${encodeURIComponent(email)}`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (!response.data.error) {
        setStatusMessage("Obuna bo'lganingiz uchun rahmat!");
      } else {
        setStatusMessage('Subscription failed: ' + (response.data.message || 'Unknown error.'));
      }
    } catch (error) {
      console.error('An error occurred:', error);
      setStatusMessage('Xatolik yuz berdi: ' + error.message);
    }
  };

  return (
    <div className="pre-form">
      <div className="form">
        <input
          type="email"
          name="email"
          id="email"
          placeholder="E-Mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="submit" onClick={handleSubmit}>
          Obuna
        </button>
      </div>
      {statusMessage && <p className='massage'>{statusMessage}</p>}
    </div>
  );
}

function App() {
  return (
    <main>
      <div className="logo">
        <img src={logo} alt="logo" />
      </div>

      <div className="mazgi">
        <p className="text">Tez orada ishga tushiramiz...</p>
        <SubscriptionForm />
      </div>

      <div className="socials">
        <div className="items">
          <a href="https://instagram.com/nuqtauz_team" className="social" target="_blank" rel="noreferrer">
              <span className="social_icon sicon_instagram"></span>
          </a>

          <a href="https://t.me/nuqtauz_team" className="social" target="_blank" rel="noreferrer">
              <span className="social_icon sicon_telegram"></span>
          </a>

          <a href="https://x.com/nuqtauz_team" className="social" target="_blank" rel="noreferrer">
              <span className="social_icon sicon_twitter"></span>
          </a>

          <a href="https://youtube.com/@nuqta-uzbekistan" className="social" target="_blank" rel="noreferrer">
              <span className="social_icon sicon_youtube"></span>
          </a>
        </div>
      </div>
    </main>
  );
}

export default App;